*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.mb-\[15px\] {
  margin-bottom: 15px;
}

.ml-2 {
  margin-left: .5rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[500px\] {
  max-height: 500px;
}

.max-h-\[600px\] {
  max-height: 600px;
}

.min-h-0 {
  min-height: 0;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-\[25px\] {
  padding-top: 25px;
}

.text-center {
  text-align: center;
}

.font-ed-lavonia {
  font-family: ED Lavonia, sans-serif;
}

.font-gt-presurra {
  font-family: GT Pressura Mono, sans-serif;
}

.font-rework-micro {
  font-family: Rework Micro, sans-serif;
}

.font-yorick {
  font-family: Yorick, sans-serif;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[1\.25px\] {
  letter-spacing: 1.25px;
}

.text-gco-white {
  --tw-text-opacity: 1;
  color: rgb(239 233 231 / var(--tw-text-opacity));
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: ED Lavonia;
  src: url("EDLavonia-Regular.600d7a65.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: GT Pressura Mono;
  src: url("GT-Pressura-Mono-Regular.90ecc4f2.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Rework Micro;
  src: url("ReworkMicro-Bold.a40a6f4a.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Yorick;
  src: url("Yorick-Italic-205TF.3e441fef.woff2") format("woff2");
  font-style: italic;
  font-weight: normal;
}

body, html {
  overflow: hidden;
}

@media (width >= 1024px) {
  body, html {
    overflow: auto;
  }
}

a {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a:active {
  opacity: .1;
}

@media (hover: hover) {
  a:hover {
    opacity: .5;
  }
}

.bg-anim {
  --tw-bg-opacity: 1;
  background-color: rgb(24 21 22 / var(--tw-bg-opacity));
}

@media (width >= 1024px) {
  .custom-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (width >= 1536px) {
  .custom-container {
    padding-left: 63px;
    padding-right: 63px;
  }
}

.header-text-1 svg {
  width: clamp(1.05813rem, 2vw, 1.61063rem);
}

.header-text-size {
  font-size: clamp(.625rem, 1.2vw, .8125rem);
  line-height: 1.2;
}

.header-title {
  font-size: clamp(1.8125rem, 3.6vw, 2.625rem);
}

.header-large-image {
  margin-top: clamp(2.725rem, 7.06vw, 6.2875rem);
  margin-bottom: clamp(3.225rem, 10.06vw, 7.5375rem);
}

.header-large-image svg {
  --tw-translate-x: 20%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
}

.bg-anim {
  background-color: #181516;
}

@keyframes bganim {
  0%, 10% {
    background-color: #181516;
  }

  16% {
    background-color: #52231a;
  }

  32% {
    background-color: #895952;
  }

  48% {
    background-color: #b29185;
  }

  64% {
    background-color: #895952;
  }

  80% {
    background-color: #52231a;
  }

  90%, 100% {
    background-color: #181516;
  }
}

@media (width >= 768px) {
  .md\:pt-8 {
    padding-top: 2rem;
  }
}

@media (width >= 1024px) {
  .lg\:fixed {
    position: fixed;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:z-10 {
    z-index: 10;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:pt-\[3\.125rem\] {
    padding-top: 3.125rem;
  }

  .lg\:pt-\[37px\] {
    padding-top: 37px;
  }

  .lg\:text-center {
    text-align: center;
  }
}
/*# sourceMappingURL=index.62584755.css.map */
