@font-face {
  font-family: 'ED Lavonia';
  src: url('./fonts/ed-lavonia/EDLavonia-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'GT Pressura Mono';
  src: url('./fonts/gt-pressura-mono/GT-Pressura-Mono-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Rework Micro';
  src: url('./fonts/rework-micro/ReworkMicro-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Yorick';
  src: url('./fonts/yorick/Yorick-Italic-205TF.woff2') format('woff2');
  font-style: italic;
  font-weight: normal;
}