body,
html {
  @apply overflow-hidden lg:overflow-auto;
}

.inner {
  // @include pixelsToResponsiveUnit(padding-top, 50px, 53.5px, 53.5px);
}

a {
  @apply transition ease-in-out duration-300 active:opacity-10;

  @media (hover: hover) {
    @apply hover:opacity-50;
  }
}

.bg-anim {
  @apply bg-gco-black;
}

.custom-container {
  @apply lg:px-10 2xl:px-[63px];
}

.header-text-1 {
  svg {
    @include pixelsToResponsiveUnit(width, 16.93px, 20px, 25.77px);
  }
}

.header-text-size {
  @include pixelsToResponsiveUnit(font-size, 10px, 12px, 13px);
  line-height: 1.2;
}

.header-title {
  @include pixelsToResponsiveUnit(font-size, 29px, 36px, 42px);
}

.header-large-image {
  @include pixelsToResponsiveUnit(margin-top, 43.6px, 70.6px, 100.6px);
  @include pixelsToResponsiveUnit(margin-bottom, 51.6px, 100.6px, 120.6px);

  svg {
    @apply absolute top-1/2 translate-x-[20%] -translate-y-1/2;
  }
}

.bg-anim {
  // animation: bganim 12s infinite alternate;
  background-color: #181516;
}

@keyframes bganim {
  0%,
  10% {
    background-color: #181516;
  }

  16% {
    background-color: #52231a;
  }

  32% {
    background-color: #895952;
  }

  48% {
    background-color: #b29185;
  }

  64% {
    background-color: #895952;
  }

  80% {
    background-color: #52231a;
  }

  90%,
  100% {
    background-color: #181516;
  }
}
